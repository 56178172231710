import React, { useEffect } from "react"
// import * as AOS from "aos/dist/aos"
import AOS from "aos"
import styled from "styled-components"

import Landing from "./Landing"
import Venue from "./Venue"
import History from "./History"
import Tickets from "./Tickets"
import Artists from "./Artists"
import LinkBlock from "../elements/linkBlock"
import media from "../../styles/media"

const ArtistsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 200px;
  margin: 0 auto;
  width: max-content;

  > a {
    height: inherit;
  }
  ${media.tablet`
    height: 400px;
  `}
`

const Home = () => {
  useEffect(() => {
    AOS.init()
  }, [])
  return (
    <>
      <Landing />
      <Artists
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-offset="100"
      />
      <Venue />
      <Tickets
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-offset="100"
      />
      <History
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-offset="100"
      />
    </>
  )
}

export default Home

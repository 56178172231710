import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import media from "../../styles/media"

import LinkBlock from "../elements/linkBlock"

const Container = styled.section`
  padding: var(--gutter-l) var(--gutter-s) var(--gutter-l) var(--gutter-s);
  position: relative;
  padding: 0 var(--gutter-s);
  margin-bottom: calc(0px - var(--gutter-l));
  ${media.tablet`
    padding: 0 var(--gutter-l);
  `}
`

const History = ({ ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      historyImage: file(relativePath: { eq: "history-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container {...props}>
      <LinkBlock
        to="/about#history"
        label="history"
        fullscreen
        img={data.historyImage}
      />
    </Container>
  )
}

export default History

import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React, { useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive"
import { Link as ScrollLink } from "react-scroll"
import styled from "styled-components"
import landingImg from "../../../images/landing-2024.jpg"

import media from "../../../styles/media"
import Parallax from "../../elements/parallax"

const Container = styled.section`
  position: relative;
  padding: 0 var(--gutter-s);
  margin-top: calc(-7px - var(--gutter-s));
  margin-bottom: 30px;
  .p {
    z-index: 10;
  }
  ${media.tablet`
    padding: 0 var(--gutter-l);
    margin-top: calc(4px - var(--gutter-l));
  `}
  ${media.laptop`
    padding: 0 var(--gutter-l);
    margin-top: calc(-7px - var(--gutter-l));
    margin-bottom: 0;
  `}
`

const Background = styled(Parallax)`
  opacity: 1;
  .hero-wrapper {
    filter: brightness(100%);
    min-height: calc(100vh - 340px);
    max-height: auto;
  }
  ${media.tablet`
    .hero-wrapper {
      min-height: auto;
    }
  `}
`
const TextContainer = styled.div`
    min-width: 300px;
    font-weight: 900;
    font-family: circular-std-black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    .py {
      padding-top: 2rem;
    }
    .pt {
      padding-top: 5rem;
    }
    h1 {
      font-size: 2rem;
    }
    p {
      padding: 0.5rem 0;
      font-size: 1rem;
      &.underlined {
        text-decoration: underline;
        text-underline-offset: 10px;
      }
    }
    ${media.mobileL`
      min-width: 400px;
      p {
        font-size: 1.3rem;
      }
      .pt {
        padding-top: 4rem;
      }
      h1 {
        font-size: 3rem;
      }
    `}
    ${media.tablet`
      min-width: 600px;
      p {
        font-size: 1.2rem;
      }
    `}
    ${media.laptop`
      min-width: 900px;
      h1 {
        font-size: 4rem;
      }
      p {
        font-size: 1.7rem;
      }
    `}
    ${media.laptopL`
      min-width: 1400px;
      h1 {
        font-size: 5rem;
      }
      p {
        font-size: 2rem;
      }
      .pt {
        padding-top: 8rem;
      }
    `}

`

const BuyTicketsContainer = styled.a`
  font-weight: 900;
  font-family: circular-std-black;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: beige;
  font-size: 1.2rem;
  text-shadow: 0 3px 4px black;
  overflow-x: hidden;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: beige;
    left: 0;
    bottom: 0;
    transition: all 250ms ease-out;
    transform: translateX(0);
  }
  &:hover {
    color: beige;
  }
  ${media.tablet`
    font-size: 2rem;
    &:after {
      height: 4px;
    }
    &:hover {
      &:after {
        transform: translateX(50%);
      }
    }
  `}
`

const LandingContainer = styled.div``

const Heading = styled(Parallax)`
  font-size: 1.75rem;
  position: absolute;
  padding-bottom: 1em;
  bottom: calc(0px - var(--gutter-s));
  right: calc(0px + var(--gutter-s));
  ${media.tablet`
    transform: rotate(-90deg) translate(55%,100%);
    transform-origin: 55% 0;
    padding-bottom: 0;
  `}
`

const Landing = () => {
  const [loaded, setLoaded] = useState(false)
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" })
  const data = useStaticQuery(graphql`
    query {
      heroImageDesktop: file(relativePath: { eq: "landing-2024.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    window.setTimeout(() => {
      setLoaded(true)
    }, 500)
  }, [])

  const buyTicketsClicked = e => {
    e.stopPropagation()
  }

  return (
    <Container loaded={loaded}>
      <Background factor={2} axis="y">
        {/* <Link to="/pr"> */}
        <LandingContainer>
          <Img
            fluid={data.heroImageDesktop.childImageSharp.fluid}
            className="hero-wrapper"
            loading="eager"
          />
          <TextContainer>
            <div className="pt">
              <h1>Cape Town</h1>
              <h1>28 March - 30 March 2025</h1>
            </div>
          </TextContainer>
        </LandingContainer>
        {/* </Link> */}
      </Background>
      {/* <Heading factor={1} axis="x">
        <h4 className="font-black">
          <ScrollLink
            to="artists"
            spy={true}
            smooth={true}
            offset={80}
            duration={500}
          >
            &#8592;
          </ScrollLink>{" "}
          New dates are out
        </h4>
      </Heading> */}
    </Container>
  )
}

export default Landing

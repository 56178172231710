/**
 * @flow
 */

import React from "react"
import styled from "styled-components"

import ImageWrapper from "../../elements/imageWrapper"

import media from "../../../styles/media"

// type Props = {
//   img: String,
// }

const Container = styled.div`
  display: grid;
  grid-template: auto auto auto auto / 1.9fr 0.9fr 0.3fr 1fr;
  ${media.tablet`
    grid-template: auto auto auto auto / 2fr 0.8fr 0.3fr 1fr;
  `}
`

const Photo = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  overflow: hidden;
  .gatsby-image-wrapper {
    min-height: 500px;
  }
  ${media.tablet`
    .gatsby-image-wrapper {
      min-height: 400px;
      picture {
        img {
          transition: transform 2500ms ease-out !important;
          transform: ${props => (props.zoom ? `scale(1.1)` : `scale(1)`)};
        }
      }
    }
  `}
`

const Location = styled.div`
  grid-row: 2 / 3;
  grid-column: 3 / 5;
  transform: rotate(-90deg) translate(0, 25px);
  transform-origin: bottom left;
  display: flex;
  align-items: flex-end;
  white-space: nowrap;
  font-size: 0.9rem;
`

const Style = styled.div`
  font-size: 0.9rem;
  padding: 10px 0 0;
  grid-row: 4 / 5;
  grid-column: 1 / 2;
`

const Name = styled.div`
  grid-row: 2 / 3;
  grid-column: 2 / 5;
  position: relative;
  z-index: 1;
  padding-top: var(--gutter-s);
  a {
    font-size: 1.75rem;
    line-height: 1;
  }
  ${media.tablet`
    a {
    font-size: 3rem;
    }
  `}
`

const noMatchBlock = ({ img }) => {
  return (
    <Container>
      <Name className="font-black">
        <a>No Matches</a>
      </Name>
      <Photo>
        <ImageWrapper src={img} />
      </Photo>
      <Location>Sorry &#8213; no matching artists</Location>
      <Style> Please search again </Style>
    </Container>
  )
}

noMatchBlock.defaultProps = {
  img: "blank.jpg",
}

export default noMatchBlock

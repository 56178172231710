import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Swiper } from "swiper/dist/js/swiper.esm"

import FridayTicket from "../../../images/svg/friday-ticket.svg"
import SaturdayTicket from "../../../images/svg/saturday-ticket.svg"
import SundayTicket from "../../../images/svg/sunday-ticket.svg"
import PlaceholderTicket from "../../../images/svg/ticket-placeholder-updated.svg"
import PlaceholderTicketJHB from "../../../images/svg/ticket-placeholder-JHB.svg"
// import PlaceholderTicketCT from "../../../images/svg/SAITC-ticket-2025.svg"
import PlaceholderTicketCT from "../../../images/svg/SAITC-ticket-2025-flattened.svg"
import HowlerLogo from "../../../images/svg/howler-logo.svg"
// import ticket_001 from "../../../images/svg/ticket-001.svg"
import WeekendTicket from "../../../images/svg/weekend-ticket.svg"
import media from "../../../styles/media"

const Container = styled.div`
  padding: var(--gutter-l) var(--gutter-s) var(--gutter-l) var(--gutter-s);
  h1 {
    font-size: 3rem;
    line-height: 1;
  }
  h4 {
    font-size: 1.25rem;
    margin-bottom: var(--gutter-l);
  }
  .swiper-container {
    margin-bottom: var(--gutter-m);
  }
  ${media.tablet`
    padding: calc(1.5 * var(--gutter-l)) 0 calc(1.5 * var(--gutter-l)) var(--gutter-l);
    h1 {
      font-size: 5.5rem;
    }
    h4 {
      font-size: 1.75rem;
    }
    .swiper-container {
      margin-bottom: var(--gutter-l);
    }
  `}
`

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    width: 100%;
    height: auto;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  ${media.tablet`
    width: 55vw;
    opacity: 0.6;
    transition: opacity 250ms ease-out;
    &:last-child {
      margin-right: calc(101% - 55vw);
    }
    &.swiper-slide-active {
      opacity: 1;
    }
  `}
`

const ControlsContainer = styled.div`
  display: grid;
  grid-template: auto auto / 1fr auto;
  grid-gap: 0px 80px;
  height: 60px;
  align-items: center;
  .gateway {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    padding-top: var(--gutter-s);
    display: flex;
    align-items: center;
    span {
      font-size: 0.9rem;
      margin-right: calc(0.3 * var(--gutter-s));
    }
    a {
      img {
        max-height: 15px;
        width: auto;
        transform: translateY(2px);
      }
    }
  }
  .terms {
    a {
      font-size: 1rem;
      position: relative;
      overflow: hidden;
      display: inline-block;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--black);
        transition: var(--transition-medium);
        transform: translateX(0%);
      }
    }
  }
  .controls {
    display: flex;
    .active-slide {
      margin-right: calc(0.5 * var(--gutter-s));
      padding: 1rem;
      opacity: 1;
    }
    div {
      opacity: 0.25;
      transition: all 250ms ease-out;
      padding: 1rem;
      &.active {
        opacity: 1;
      }
      &:first-child {
        margin-right: 10px;
      }
    }
  }
  ${media.laptop`
    grid-template: auto / 1fr auto calc(100% - 55vw - 80px);
    grid-gap: 0px 80px;
    .gateway {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      padding-top: 0;
      span {
        font-size: 1rem;
      }
    }
    .terms {
      a {
        &:hover {
          :after {
            transform: translateX(50%);
          }
        }
      }
    }
    .controls {
      justify-self: flex-end;
      padding-right: var(--gutter-l);
    }
  `}
  ${media.tablet`
    .controls {
      padding-right: var(--gutter-l);
    }
  `}
`

const BuyTicket = styled.a`
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  color: var(--carnation);
  position: relative;
  overflow: hidden;
  /* transform: translate(calc(100% + 15%), -50%); */
  transform: translate(calc(100% - 50%));
  font-size: 1rem;
  z-index: 99;
  transition: opacity 0.3s ease;
  margin-left: 20%;
  margin-top: -0.6em;
  &:after {
    content: "";
    position: absolute;
    height: 2px;
    background-color: var(--carnation);
    width: 100%;
    left: 0;
    bottom: 0;
    transition: var(--transition-medium);
  }
  &:hover {
    &:after {
      transform: translateX(50%);
    }
  }
  &.animate-opacity {
    opacity: 0;
  }
  ${media.tablet`
    /* width: 55vw; */
    font-size: 1.75rem;
    transform: translate(calc(100% - 85%));
  `}
`

const Tickets = ({ ...props }) => {
  const swiperElement = useRef(null)
  const swiper = useRef(null)

  const [moveFrameId, setMoveFrameId] = useState(null)
  const [activeSlide, setActiveSlide] = useState(0)
  const [transition, setTransition] = useState(false)

  const slidePrev = () => {
    if (swiper.current) {
      swiper.current.slidePrev()
    }
  }

  const slideNext = () => {
    if (swiper.current) {
      swiper.current.slideNext()
    }
  }

  const onSwiperMove = () => {
    if (activeSlide !== swiper.current.realIndex) {
      setActiveSlide(swiper.current.realIndex)
    }
  }

  useEffect(() => {
    swiper.current = new Swiper(swiperElement.current, {
      speed: 1000,
      loop: false,
      slidesPerView: 1,
      spaceBetween: 80,
      touchStartPreventDefault: false,
      breakpointsInverse: true,
      breakpoints: {
        767: {
          slidesPerView: "auto",
        },
      },
      on: {
        sliderMove: () => {
          setMoveFrameId(window.requestAnimationFrame(onSwiperMove))
        },
        touchStart: () => {
          setTransition(true)
        },
        touchEnd: () => {
          setTransition(false)
        },
        slideChangeTransitionStart: () => {
          if (swiper.current) {
            setActiveSlide(swiper.current.realIndex)
            setTransition(true)
          }
        },
        slideChangeTransitionEnd: () => {
          setTransition(false)
        },
      },
    })
    return function cleanup() {
      window.cancelAnimationFrame(moveFrameId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container name="tickets" id="tickets" {...props}>
      <h1 className="font-black">tickets</h1>
      <div className="swiper-container slider-swipe" ref={swiperElement}>
        <div className="swiper-wrapper">
          {/* <Slide className="swiper-slide">
            <a
              href="https://www.webtickets.co.za/v2/EventCategory.aspx?itemid=1514941360#tickets"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
              draggable="false"
            >
              <img
                draggable="false"
                src={PlaceholderTicketJHB}
                alt="Placeholder Pass"
              />
            </a>
          </Slide> */}
          <Slide className="swiper-slide">
            <a
              href="#"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
              draggable="false"
            >
              <img
                draggable="false"
                src={PlaceholderTicketCT}
                alt="Placeholder Pass"
              />
            </a>
          </Slide>
          {/* <Slide className="swiper-slide">
            <img src={PlaceholderTicket} alt="Placeholder Pass" />
          </Slide>
          <Slide className="swiper-slide">
            <img src={FridayTicket} alt="Friday Pass" />
          </Slide>
          <Slide className="swiper-slide">
            <img src={SaturdayTicket} alt="Saturday Pass" />
          </Slide>
          <Slide className="swiper-slide">
            <img src={SundayTicket} alt="Sunday Pass" />
          </Slide>
          <Slide className="swiper-slide">
            <img src={WeekendTicket} alt="Weekend Pass" />
          </Slide> */}
        </div>
        {/* <BuyTicket
          href="https://www.webtickets.co.za/v2/event.aspx?itemid=1491704951"
          target="_blank"
          rel="noreferrer noopener"
          className={
            transition
              ? "animate-opacity font-black mouse-link"
              : "font-black mouse-link"
          }
        >
          Buy Tickets &#8599;
        </BuyTicket> */}
      </div>
      <ControlsContainer>
        <div className="gateway">
          <span>Available end of July 2024</span>
        </div>
        <div className="terms">
          <Link to="/terms" className="mouse-link">
            terms
          </Link>
        </div>
        <div className="controls">
          <div className="active-slide"> 0{activeSlide + 1} &#8213; 02 </div>
          <div
            onClick={slidePrev}
            className={activeSlide > 0 ? `active mouse-link` : `mouse-link`}
          >
            &#8592;
          </div>
          <div
            onClick={slideNext}
            className={activeSlide < 0 ? `active mouse-link` : `mouse-link`}
          >
            &#8594;
          </div>
        </div>
      </ControlsContainer>
    </Container>
  )
}

export default Tickets

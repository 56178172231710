import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
// import { TweenLite } from "gsap"
// import { isMobile } from "react-device-detect"

// eslint-disable-next-line no-unused-vars
const Parallax = ({ children, factor, axis, ...props }) => {
  const content = useRef(null)
  // const [frameId, setFrameId] = useState(null)
  // const [animate, setAnimate] = useState(false)

  useEffect(() => {
    /*
    if (!isMobile) {
      window.addEventListener("scroll", onScroll, false)
      return function cleanup() {
        window.removeEventListener("scroll", onScroll, false)
        window.cancelAnimationFrame(frameId)
      }
    }
    */
  })
  /*
  const onScroll = () => {
    setFrameId(window.requestAnimationFrame(animateContent))
    if (content.current) {
      let elementStart = content.current.getBoundingClientRect().top
      let elementStop =
        elementStart + content.current.getBoundingClientRect().height
      if (0 < elementStop && elementStart < window.innerHeight) {
        setAnimate(true)
      } else {
        setAnimate(false)
      }
    }
  }
  */
  /*
  const animateContent = () => {
    if (animate) {
      let yMove = window.pageYOffset * (factor / 10)
      TweenLite.to(content.current, 0.16, {
        x: axis === "x" ? yMove : null,
        y: axis === "y" ? yMove : null,
      })
    }
  }
  */

  return (
    <div {...props}>
      <div ref={content}> {children} </div>
    </div>
  )
}

Parallax.propTypes = {
  children: PropTypes.node,
  factor: PropTypes.number,
  axis: PropTypes.string,
}

export default Parallax

import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import LinkBlock from "../../elements/linkBlock"

import media from "../../../styles/media"

const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  min-height: 85vh;
  padding: var(--gutter-l) var(--gutter-s) calc(2 * var(--gutter-l));
  .gatsby-image-wrapper {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    picture {
      img {
        object-position: 0% 0% !important;
      }
    }
  }
  ${media.tablet`
    padding-bottom: calc(2 * var(--gutter-l));
  `}
`

const VenueContent = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  h2 {
    font-size: 1.25rem;
    margin-bottom: var(--gutter-s);
  }
  h4 {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: var(--gutter-m);
    margin-top: var(--gutter-m);
    span {
      font-size: 2.75rem;
      display: block;
    }
  }
  a {
    font-size: 1rem;
    margin-bottom: var(--gutter-m);
  }
  ${media.tablet`
    h2 {
      font-size: 1.75rem;
      margin-bottom: var(--gutter-s);
    }
    h4 {
      font-size: 2.5rem;
      margin-bottom: var(--gutter-m);
      span {
        font-size: 5.5rem;
      }
    }
  `}
  ${media.laptop`
      h4 {
        margin-top: var(--gutter-m);
      }
  `}
`

const LinksContainer = styled.div`
  margin-top: calc(0px - var(--gutter-l));
  position: relative;
  padding: 0 var(--gutter-s);
  display: grid;
  grid-template:
    auto /
    calc(50vw - var(--gutter-s))
    calc(50vw - var(--gutter-s));
  ${media.tablet`
    margin-top: calc(0px - var(--gutter-l));
    padding: 0 var(--gutter-l);
    grid-template:
      auto /
      calc(50vw - var(--gutter-l))
      calc(50vw - var(--gutter-l));
  `}
`

const Map = () => {
  const data = useStaticQuery(graphql`
    query {
      entertainmentImage: file(relativePath: { eq: "entertainment.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 770) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      marketplaceImage: file(relativePath: { eq: "marketplace.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 770) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mapImage: file(relativePath: { eq: "map-c.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2590) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div name="venue" id="venue">
      <MapContainer>
        <VenueContent>
          <h2 className="font-black">venue</h2>
          <h4>
            <span className="font-black">Cape Town</span>
            {/* V&amp;A Waterfront <br /> */}
            To be confirmed <br />
            {/* (2024) */}
          </h4>
          {/* <a
            href="https://goo.gl/maps/aX3ov52bB1jVaabZ7"
            target="_blank"
            rel="noreferrer noopener"
            className="font-black mouse-link"
          >
            Get directions on Google Maps &#8594;
          </a> */}
        </VenueContent>
        <Img fluid={data.mapImage.childImageSharp.fluid} />
      </MapContainer>
      <LinksContainer>
        <LinkBlock
          to="/entertainment"
          label="entertainment"
          img={data.entertainmentImage}
        />
        <LinkBlock
          to="/marketplace"
          label="marketplace"
          img={data.marketplaceImage}
        />
      </LinksContainer>
    </div>
  )
}

export default Map

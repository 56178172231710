/**
 * @flow
 */

import React from "react"
import styled from "styled-components"

import ImageWrapper from "../../elements/imageWrapper"

import media from "../../../styles/media"

import MailIcon from "../../../images/svg/mail-icon.svg"

// type Props = {
//   name: string,
//   img: String,
//   country: string,
//   town: string,
//   // style: string,
//   insta_handle: string,
//   email_address: string,
//   zoom: boolean,
// }

const Container = styled.div`
  display: grid;
  grid-template: auto auto auto auto / 1.9fr 0.9fr 0.3fr 1fr;
  ${media.tablet`
  grid-template: auto auto auto auto / 2fr 0.8fr 0.3fr 1fr;
  `}
`

const Photo = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  overflow: hidden;
  /* opacity: 0.7 !important; */
  .gatsby-image-wrapper {
    min-height: 300px;
  }
  ${media.tablet`
    .gatsby-image-wrapper {
      min-height: 400px;
      picture {
        img {
            transition: transform 2500ms ease-out !important;
            transform: ${props => (props.zoom ? `scale(1.1)` : `scale(1)`)};
        }
      }
      &:hover {
        picture {
          img {
              transition: transform 2500ms ease-out !important;
              transform: scale(1.1);
          }
        }
      }
    }
  `}
`

const Location = styled.div`
  grid-row: 2 / 3;
  grid-column: 3 / 5;
  transform: rotate(-90deg) translate(0, 25px);
  transform-origin: bottom left;
  display: flex;
  align-items: flex-end;
  white-space: nowrap;
  font-size: 0.9rem;
`

const Insta = styled.div`
  padding: 0 0 10px;
  grid-row: 1 / 2;
  grid-column: 1 / 5;
  font-size: 0.9rem;
  svg {
    width: 16px;
    height: 16px;
    margin-bottom: -3px;
    margin-right: 10px;
  }
  img {
    width: 20px;
    height: 20px;
    margin-bottom: -5px;
    margin-right: 10px;
  }
`

const Name = styled.div`
  grid-row: 2 / 3;
  grid-column: 2 / 5;
  position: relative;
  z-index: 1;
  padding-top: var(--gutter-s);
  a {
    font-size: 1.75rem;
    line-height: 1;
  }
  ${media.tablet`
    a {
    font-size: 3rem;
    }
  `}
`

const PreviewBlock = ({
  name,
  img,
  country,
  town,
  // style,
  insta_handle,
  email_address,
  zoom,
}) => {
  return (
    <Container>
      <Name className="font-black">
        <a
          href={`https://www.instagram.com/${insta_handle}/`}
          target="_blank"
          rel="noreferrer noopener"
          className="mouse-link"
        >
          {name}
        </a>
      </Name>
      <Insta className="mouse-link">
        <a
          href={`mailto:${email_address}`}
          target="_top"
          rel="noopener noreferrer"
        >
          <img src={MailIcon} />
        </a>
        <svg viewBox="0 0 24 24">
          <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
        </svg>
        <a
          href={`https://www.instagram.com/${insta_handle}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          @{insta_handle}
        </a>
      </Insta>
      <Photo zoom={zoom}>
        <ImageWrapper src={img} />
      </Photo>
      <Location>
        {town && `${town} ―`} {country}
        {/* {town} &#8213; {country} */}
      </Location>
      {/* <Style> {style} </Style> */}
    </Container>
  )
}

PreviewBlock.defaultProps = {
  name: "Name",
  img: "placeholder.jpg",
  country: "Country",
  town: "Town",
  email_address: "email@default.com",
  // style: "Style",
  insta_handle: "/",
}

export default PreviewBlock

/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState, useRef, useContext } from "react"
import styled from "styled-components"
import { Swiper } from "swiper/dist/js/swiper.esm"
import Select from "react-select"
import { navigate, Link } from "gatsby"

import { ArtistYearContext } from "./context"

import ARTISTS_DATA from "./data"
import PreviewBlock from "./previewBlock"
import NoMatchBlock from "./noMatchBlock"

import media from "../../../styles/media"

const Container = styled.section`
  z-index: 9 !important;
  background-color: var(--white);
  position: relative;
  z-index: 1;
  padding: var(--gutter-l) var(--gutter-s) var(--gutter-l) var(--gutter-s);
  /* overflow: hidden; */
  h1 {
    font-size: 3rem;
    line-height: 1;
  }
  h4 {
    font-size: 1.25rem;
  }
  h6 {
    color: var(--carnation);
    font-size: 1em;
    margin-bottom: 5em;
  }
  ${media.tablet`
    padding: calc(1.5 * var(--gutter-l)) 0 calc(1.5 * var(--gutter-l)) var(--gutter-l);
    h1 {
      font-size: 5.5rem;
    }
    h4 {
      font-size: 1.75rem;
    }
  `}
`

const PastArtistSelector = styled.div`
  display: none;
  grid-template: auto / 1fr;
  padding: var(--gutter-s) 0 var(--gutter-l);
  justify-items: flex-start;
  a {
    font-size: 1.25rem;
    .link-primary {
      position: relative;
      color: var(--carnation);
      overflow: hidden;
      display: inline-block;
      &:after {
        content: "";
        position: absolute;
        background-color: var(--carnation);
        width: 100%;
        left: 0;
        bottom: 0;
        height: 2px;
        transition: all 250ms ease-out;
        transform: translateX(0%);
      }
      &:hover {
        &:after {
          transform: translateX(50%);
        }
      }
    }
  }
  .link-block {
    display: grid;
  }
  ${media.tablet`
    display: grid;
    grid-template: auto / calc((2 * 35vw) + 160px) 1fr;
    justify-items: center;
    margin-left: 15em;
    margin-top: -9.5em;
  `}
  ${media.laptop`
    justify-items: flex-end;
    margin-left: 0;
    margin-top: -9em;
  `}
`

const SwiperContainer = styled.div`
  margin-bottom: var(--gutter-m);
  ${media.tablet`
    margin-bottom: var(--gutter-l);
  `}
`

const Slide = styled.div`
  ${media.tablet`
    width: 35vw;
    &:last-child {
      margin-right: calc(101% - 35vw);
    }
  `}
`
const SelectBoxes = styled.div`
  display: flex;
  align-items: center;
  grid-column: 1/2;
  p {
    font-size: 0.9rem;
  }
  .select-box {
    width: 100%;
    max-width: 150px;
    .css-1wa3eu0-placeholder {
      padding-left: 1.5rem;
    }
    margin: 0 5px;
    font-size: 0.9rem;
    .css-yk16xz-control {
      border: none;
      outline: none;
      /* &:after {
        content: "↓";
        position: relative;
        right: 30%;
      } */
    }
    .css-1pahdxg-control {
      border: none !important;
      outline: none;
      box-shadow: none;
      /* &:after {
        content: "↓";
        position: relative;
        right: 30%;
      } */
    }
    .css-1g48xl4-IndicatorsContainer {
      display: none;
    }
    .css-1wa3eu0-placeholder {
      color: #000;
      border-bottom: 2px black solid;
      padding-left: 2px;
    }
    .css-19bqh2r,
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    /* .css-26l3qy-menu {
      z-index: 999999 !important;
    } */
  }
  ${media.laptop`
    grid-column: 1;
    .slash {
      padding-right: 2.5rem;
    }
  `}
`

const Progress = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  display: grid;
  grid-template: auto / auto 1fr;
  align-items: center;
  .active-slide {
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
    span {
      min-width: 20px;
      &.divider {
        margin: 0 2px;
        text-align: center;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
  .bar {
    width: calc(100% * ((2.8 / 4.1)) - 10px);
    height: 2px;
    background-color: rgba(0, 0, 0, 0.25);
    position: relative;
    &:after {
      content: "";
      position: absolute;
      height: 2px;
      width: ${props => props.progress}%;
      left: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.75);
      transition: var(--transition-medium);
    }
  }
  ${media.laptop`
    grid-row: 1 / 2;
    grid-column: 2 / 3;
  `}
`

const Controls = styled.div`
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  display: flex;
  div {
    opacity: 0.25;
    transition: all 250ms ease-out;
    padding: 1rem;
    &.active {
      opacity: 1;
      padding: 1rem;
    }
    &:first-child {
      margin-right: 10px;
    }
  }
  ${media.laptop`
    grid-row: 1 / 2;
    grid-column: 3 / 4;
  `}
`

const ControlsContainer = styled.div`
  display: grid;
  grid-template: auto auto / 1fr auto;
  align-items: center;
  ${media.tablet`
    height: 60px;
    grid-template: auto / 35vw 35vw auto;
    grid-gap: 0px 80px;
  `}
`

const MobileApplyLink = styled.div`
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  color: var(--carnation);
  .link-block {
    display: grid;
  }
  ${media.tablet`
    display: none;
  `}
`

const Artists = ({ ...props }) => {
  const swiperElement = useRef(null)
  const swiper = useRef(null)

  const [moveFrameId, setMoveFrameId] = useState(null)
  const [activeSlide, setActiveSlide] = useState(0)

  const [zoom, setZoom] = useState(false)

  const context = useContext(ArtistYearContext)

  var bar_progress = Math.round(((activeSlide + 1) / ARTISTS_DATA.length) * 100)

  const [country, setCountry] = useState("country")
  const [style, setStyle] = useState("style")
  const [year, setYear] = useState("2024")

  const styleItems = new Set()
  const uniqueStyle = ARTISTS_DATA.filter(artist => {
    if (styleItems.has(artist.style)) {
      return false
    }
    styleItems.add(artist.style)
    return true
  })

  const countryItems = new Set()
  const uniqueCountry = ARTISTS_DATA.filter(artist => {
    if (countryItems.has(artist.country)) {
      return false
    }
    countryItems.add(artist.country)
    return true
  })

  const navigateToPastArtists = year => {
    context.updateYear(year)
    navigate("/artists")
  }

  const renderArtists = () => {
    return ARTISTS_DATA.filter(artist => {
      return (
        (artist.country === country || country === "country") &&
        artist.year === year
      )
    }).map((artist, i) => {
      return (
        <Slide key={i} className="swiper-slide">
          <PreviewBlock
            name={artist.name}
            img={artist.img}
            country={artist.country}
            town={artist.town}
            style={artist.style}
            insta_handle={artist.insta_handle}
            email_address={artist.email_address}
            zoom={zoom}
            year={artist.year}
          />
        </Slide>
      )
    })
  }

  const renderNoMatch = () => {
    if (renderArtists().length === 0) {
      return <NoMatchBlock />
    }
  }

  const slidePrev = () => {
    if (swiper.current) {
      swiper.current.slidePrev()
    }
  }

  const slideNext = () => {
    if (swiper.current) {
      swiper.current.slideNext()
    }
  }

  const onSwiperMove = () => {
    if (activeSlide !== swiper.current.realIndex) {
      setActiveSlide(swiper.current.realIndex)
    }
  }

  useEffect(() => {
    swiper.current = new Swiper(swiperElement.current, {
      freeMode: true,
      freeModeMomentumRatio: 0.5,
      freeModeSticky: true,
      speed: 1500,
      touchRatio: 0.75,
      longSwipesRatio: 0.2,
      touchStartPreventDefault: false,
      rebuildOnUpdate: true,
      shouldSwiperUpdate: true,
      // preventInteractionOnTransition: true,
      // normalizeSlideIndex: true,
      slidesPerView: 1,
      spaceBetween: 80,
      initialSlide: 0,
      breakpointsInverse: true,
      on: {
        sliderMove: () => {
          setMoveFrameId(window.requestAnimationFrame(onSwiperMove))
        },
        slideChangeTransitionStart: () => {
          if (swiper.current) {
            setActiveSlide(swiper.current.realIndex)
          }
        },
        touchStart: () => {
          setZoom(true)
        },
        touchEnd: () => {
          setZoom(false)
        },
      },
      breakpoints: {
        767: {
          slidesPerView: "auto",
        },
      },
    })
    return function cleanup() {
      window.cancelAnimationFrame(moveFrameId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  const handleStyleChange = selectedStyleOption => {
    var selectedStyle = selectedStyleOption.value
    setStyle(selectedStyle)
  }

  // const handleCountryChange = selectedCountryOption => {
  //   var selectedCountry = selectedCountryOption.value
  //   setCountry(selectedCountry)
  // }

  const slideTo = index => {
    swiper.current.slideTo(index, 800)
  }

  const filterChanged = selectedCountryOption => {
    slideTo(0)
    var selectedCountry = selectedCountryOption.value
    setCountry(selectedCountry)
    swiper.current.destroy(false, true)
  }

  const selectedStyleOption = style
  const selectedCountryOption = country

  bar_progress = Math.round(((activeSlide + 1) / renderArtists().length) * 100)
  return (
    <Container name="artists" {...props}>
      <h1 className="font-black">artists</h1>
      <h4>120 of the world&apos;s best tattoo artists.</h4>
      <h6>view previous event artists here.</h6>
      <PastArtistSelector>
        <div className="link-block">
          <Link to="getting-tattooed" className="mouse-link">
            <span className="link-primary">how to get tattooed&#8594;</span>
          </Link>
          <Link to="artists" className="mouse-link">
            <span className="link-primary">view all artists&#8594;</span>
          </Link>
        </div>
      </PastArtistSelector>
      <SwiperContainer
        className="swiper-container slider-swipe"
        ref={swiperElement}
      >
        <div className="swiper-wrapper"> {renderArtists()} </div>
        <div className="swiper-wrapper"> {renderNoMatch()} </div>
      </SwiperContainer>
      <ControlsContainer>
        <SelectBoxes>
          <p>filter by </p>
          {/* <Select
            value={selectedStyleOption}
            onChange={handleStyleChange}
            isClearable={true}
            isSearchable={false}
            placeholder={selectedStyleOption + " ↓"}
            className="select-box mouse-link"
            options={uniqueStyle.map((styleItem, i) => {
              return {
                key: i,
                value: styleItem.style,
                label: styleItem.style,
              }
            })}
          />
          <p className="slash">/</p> */}
          <Select
            className="select-box mouse-link"
            value={selectedCountryOption}
            // onClick={slideTo(0)}
            // onChange={handleCountryChange}
            onChange={filterChanged}
            isClearable={true}
            isSearchable={false}
            placeholder={selectedCountryOption + " ↓"}
            options={uniqueCountry.map((countryItem, i) => {
              return {
                key: i,
                value: countryItem.country,
                label: countryItem.country,
              }
            })}
          />
        </SelectBoxes>
        <Progress progress={bar_progress}>
          <div className="active-slide">
            <span>
              {activeSlide < 9 ? "0" : null}
              {activeSlide + 1}
            </span>
            <span className="divider">&#8213;</span>
            <span>
              {renderArtists().length < 10 ? "0" : null}
              {renderArtists().length}
            </span>
          </div>
          <div className="bar" />
        </Progress>
        <Controls>
          <div
            onClick={slidePrev}
            className={activeSlide > 0 ? `active mouse-link` : `mouse-link`}
          >
            &#8592;
          </div>
          <div
            onClick={slideNext}
            className={
              activeSlide < renderArtists().length - 1
                ? `active mouse-link`
                : `mouse-link`
            }
          >
            &#8594;
          </div>
        </Controls>
        <MobileApplyLink>
          <div className="link-block">
            <Link to="getting-tattooed" className="mouse-link">
              <span className="link-primary">how to get tattooed&#8594;</span>
            </Link>
            <Link to="artists" className="mouse-link">
              <span className="link-primary">view all artists&#8594;</span>
            </Link>
          </div>
        </MobileApplyLink>
      </ControlsContainer>
    </Container>
  )
}

export default Artists

/**
 * @flow
 */

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import media from "../../styles/media"

// type Props = {
//   to: String,
//   label: String,
//   img: Object,
//   fullscreen: Boolean,
// }

const Container = styled(Link)`
  display: grid;
  grid-template: 1fr / 1fr;
  justify-items: center;
  align-items: center;
  height: ${props =>
    props.fullscreen ? "200px" : "calc(50vw - var(--gutter-s))"};
  span,
  .background {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
  span {
    display: inline-block;
    position: relative;
    font-size: ${props => (props.fullscreen ? "1rem" : "1rem")};
    overflow-x: hidden;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: var(--black);
      left: 0;
      bottom: 0;
      transition: all 250ms ease-out;
      transform: translateX(0);
    }
  }
  .background {
    width: 100%;
    height: 100%;
    opacity: 0.8;
    picture {
      img {
        opacity: 0.8;
        transition: transform 7500ms ease-out !important;
      }
    }
  }
  ${media.tablet`
    height: ${props =>
      props.fullscreen ? "600px" : "calc(50vw - var(--gutter-l))"};
    span {
      font-size: ${props => (props.fullscreen ? "2rem" : "2rem")};
      &:after {
        height: 4px;
      }
    }
    &:hover {
      span {
        &:after {
          transform: translateX(50%);
        }
      }
      .background {
        picture {
          img {
            transform: scale(1.15);
            transition: transform 50000ms ease-out !important;
          }
        }
      }
    }
  `}
  ${media.laptop`
  span {
      font-size: ${props => (props.fullscreen ? "3.2rem" : "3rem")};
    }
  `}
`

const LinkBlock = ({ to, label, fullscreen, img }) => {
  return (
    <Container
      to={to}
      fullscreen={fullscreen.toString()}
      className="mouse-link"
    >
      {img && <Img fluid={img.childImageSharp.fluid} className="background" />}
      <span className="font-black"> {label} &#8594; </span>
    </Container>
  )
}

LinkBlock.defaultProps = {
  to: "/",
  label: "Label",
  fullscreen: false,
  img: null,
}

export default LinkBlock
